import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const Social = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <div className="home__social">
            <a href="https://www.linkedin.com/in/yuyiy" 
            className="home__social-icon" 
            target="_blank">
                <i className="uil uil-linkedin-alt"></i>
            </a>

            <a href="https://github.com/54yyyu" 
            className="home__social-icon" 
            target="_blank">
                <i className="uil uil-github-alt"></i>
            </a>

            <a href="https://x.com/stevenyuyy" 
            className="home__social-icon" 
            target="_blank">
                <i className="uil uil-twitter-alt"></i>
            </a>

            <a className="home__social-icon">
                <i onClick={toggleTheme} className={theme === 'dark' ? "uil uil-sun" : "uil uil-moon"}></i>
            </a>
        </div>
    )
}

export default Social;