import React, { useState } from 'react';
import './temp.css';

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }
    return (
        <section className="services section" id="services">
            <h2 className="section__title">Coming Soon!</h2>
            <span className="section__subtitle">This website is currently playing hide and seek. Spoiler alert: It's hiding.</span>

        </section>
    )
}

export default Services;