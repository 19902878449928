import React from 'react';
import "./App.css";
import { ThemeContext } from './components/home/ThemeContext';
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Temp from './components/temp/Temp';
import { useState } from "react";

const App = () => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
        .matches;
  const [theme, setTheme] = useState(prefersDark ? 'dark' : 'light');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
    <div data-theme={theme}>
      <Header />

      <main className='main'>
        <Home />
        {/* <About /> */}
        {/* <Skills />
        <Services /> */}
        <Temp />
      </main>
    </div>
    </ThemeContext.Provider>
  );
}

export default App;